<template>

    <div class="section-content pt-3">
        <div class="primary-section-content">
            <div class="main-section-heading">
                <div class="main-section-header">
                    <h1 class="main-section-header">Billing</h1>
                    <h2 class="subtitle-2 font-weight-regular mb-2">Enter your monthly recurring bills.</h2>
                </div>
            </div>

            <div class="content-view">
                <div class="table">
                    <div class="table-row">

                        <div class="table-head">Address</div>
                        <div class="table-head">&nbsp;</div>

                    </div>
                    <div class="table-row" v-for="p in properties" @click="setSelected(p)">
                        <div class="table-cell">
                            {{p.Address.address}}<br />{{ p.Address | formatAddress }}
                        </div>
                        <div class="table-cell">
                            {{p.total_entered}} of {{p.total_bills}} entered this month
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <modal v-if="showEnter" size="lg" allow-click-away="false" @close="closeWindow">
            <h3 slot="header">Enter Bills</h3>
            <div slot="body">

                <div class="form-section">
                    <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

                    <div class="table">
                        <div class="table-row">
                            <div class="table-head">Bill Name</div>
                            <div class="table-head">Amount to Bill</div>
                            <div class="table-head">Split Type</div>
                        </div>
                    </div>

                    <div class="table-row" v-for="b, i in selected.Bills" >
                        <div class="table-cell">
                            {{b.name}}
                        </div>
                        <div class="table-cell">

                            <div class="form-input-container" v-if="!b.pbill_id">
                                <div class="horizontal-form-input" :class="{'has-error': errors.has('amount') }" style="width: 125px;">
                                    <div class="form-group">
                                        <div class="form-group-icon icon">$</div>
                                        <input
                                                class="w-input input"
                                                type="text"
                                                v-model="b.amount"
                                                v-validate="'decimal:2|min_value:.01|max_value:99999'"
                                                name="amount"
                                                id="amount"
                                                :class="{'has-error': errors.has('amount') }"
                                        />

                                    </div>
                                </div>
                                <span v-show="errors.has('price')" class="status-block error-block field-error">{{ errors.first('price') }}</span>
                            </div>
                            <div v-else>
                            <span class="col-form-label">
                                ${{b.current_amount}}
                            </span>
                            <span class="float-right">

                                 <dropdown-menu
                                         align="left"
                                         width="300"
                                         name="deleteDropdown"
                                         ref="deleteDropdown"
                                 >
                                    <template slot="trigger">
                                        <button class="w-button clear-btn btn-sm">
                                            <span class="icon trash"></span>
                                        </button>
                                    </template>
                                    <template slot="dropdown" >
                                       <div class="form-section">
                                           <p>Are you sure you want to delete this bill? This action cannot be undone</p>
                                       </div>
                                        <div class="modal-footer  modal-footer-padding">
                                            <a class="close-link" @click="cancel(i)">Cancel</a>
                                            <button  class="w-button trash-btn" @click="deleteBill(b)">Delete Bill</button>
                                        </div>
                                    </template>
                                </dropdown-menu>


                                <!--<dropdown-confirmation-->
                                        <!--triggerBtnClass="btn-clear btn-sm"-->
                                        <!--triggerBtnHtml='<span class="icon trash"></span>'-->
                                        <!--@confirm=""-->
                                <!--&gt;-->
                                <!--</dropdown-confirmation>-->
                            </span>
                            </div>

                        </div>

                        <div class="table-cell" v-if="b.custom" @click="setSplits(b)">
                            <span>Custom splits</span>
                        </div>
                        <div class="table-cell" v-else-if="b.splittype == 'units'" @click="setSplits(b)">
                            <span>Split evenly by number of spaces</span>
                        </div>
                        <div class="table-cell" v-else-if="b.splittype == 'tenants'" @click="setSplits(b)">
                            <span>Split evenly by number of tenants</span>
                        </div>
                        <div class="table-cell" v-else-if="b.splittype == 'sqft'" @click="setSplits(b)">
                            <span>Split evenly by square footage</span>
                        </div>

                    </div>
                </div>


                <div class="modal-footer">
                    <a class="close-link" @click="showEnter = false">cancel</a>
                    <button class="w-button primary-btn" @click="saveBills">Save Bills</button>
                </div>
            </div>
        </modal>
        <modal v-if="showSplits" allow-click-away="true" @close="showSplits = false">
            <h3 slot="header">Set Custom Splits</h3>
            <div slot="body">

                <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                <p>Here you can split the bill up however you like.</p>
                <br />
                <div class="info-section">The total amount entered for this bill is: ${{selectedSplits.amount}}</div>
                <br />
                <div class="table">

                    <div class="table-row" v-for="u in selected.Units">
                        <div class="table-cell">
                       {{selected.Address.address}} #{{u.number}}
                    </div>
                        <div class="table-cell">

                            <div class="form-input-container">
                                <div class="horizontal-form-input" :class="{'has-error': errors.has('custom-' + u.id) }" style="width: 125px;">
                                    <div class="form-group">
                                        <div class="form-group-icon icon">$</div>
                                        <input
                                                class="w-input input"
                                                type="text"
                                                v-model="selectedSplits.custom[u.id]"
                                                v-validate="'decimal:2|min_value:0|max_value:999999'"
                                                :name="'custom-' + u.id"
                                                data-vv-as="custom amount"
                                                :id="'custom-' + u.id"
                                                :class="{'has-error': errors.has('amount') }"
                                        />

                                    </div>
                                </div>
                                <span v-show="errors.has('custom-' + u.id)" class="status-block error-block field-error">{{ errors.first('custom-' + u.id) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <a class="close-link" @click="showSplits = false">Cancel</a>
                    <button :disabled="isLoading($options.name)" class="w-button primary-btn" @click="saveSplits()">Set Custom Splits</button>
                    <span v-show="isLoading($options.name)" >
                        <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                    </span>
                </div>
            </div>

        </modal>
    </div>
</template>

<script type="text/babel">
    import DropdownConfirmation from '../assets/DropdownConfirmation.vue';
    import DropdownMenu from '../assets/DropdownMenu.vue';
    import Status from '../includes/Messages.vue';
    import Modal from '../assets/Modal.vue';

    import api from '../../assets/api.js';

    export default {
        name: "MonthlyBills",
        data() {
            return {
                properties:[],
                splitTypes:[],
                showEnter:false,
                showSplits:false,
                selected:{
                    property_id: '',
                    bills:[]
                },
                selectedSplits:{},
            }
        },
        components:{
            Modal,
            DropdownConfirmation,
            DropdownMenu,
            Status
        },
        created (){
            this.fetchData();

        },
        filters: {

        },
        methods:{
            cancel(i){
                this.$refs.deleteDropdown[i].away()
            },
            setSplits(s){

                s.custom = s.custom || {};


                this.selected.Units.forEach((u) => {
                    s.custom[u.id] = s.custom[u.id] || '';
                });

                this.selectedSplits = JSON.parse(JSON.stringify(s));
                this.showSplits = true;
            },
            saveSplits(){

                var amount = 0;
                for(var s in this.selectedSplits.custom ){
                    this.selectedSplits.custom[s] = this.selectedSplits.custom[s] || 0;
                    amount += (this.selectedSplits.custom[s] * 1);
                }

                if(!amount) {
                    this.errorSet(this.$options.name, "You have not entered any custom values.");
                } else {
                    this.selectedSplits.amount = amount;
                    this.selectedSplits.splittype = 'custom';
                }


                var _this = this;
                var index = this.selected.Bills.findIndex(function(b){
                    return b.id == _this.selectedSplits.id;
                });

                this.selected.Bills[index] = this.selectedSplits;
                this.selectedSplits = {};
                this.showSplits = false;
            },
            saveBills(){
                var data = {
                  property_bills: this.selected.Bills.filter(b => !b.pbill_id).map(b => {
                      return {
                          amount: b.amount,
                          utility_id: b.id,
                          custom: b.custom
                      }
                  })
                };

                this.validate(this).then(status => {
                    if(!status) return;
                    api.post(this, api.PROPERTIES + this.selected.id + '/utility-bills', data).then(r => {
                        this.showEnter = false;
                        this.fetchData();
                    });
                })
            },

            setSelected(p){

                this.selected = JSON.parse(JSON.stringify(p));
                this.showEnter = true;

            },
            closeWindow(){
                this.showEdit = false;
                this.showDelete = false;
                this.showAdd = false;
                this.showEnter = false;
                this.selected = {};
            },
            fetchData(){
                api.get(this, api.BILLING + 'monthly').then(r => {
                    this.properties = r.properties;
                    this.splitTypes = r.splitTypes;
                });
            },
            editItem(u){
                this.selected = u;
                this.showEdit = true;
            },
            deleteBill(b){
                api.delete(this, api.BILLING , b.pbill_id).then(r => {
                    b.current_amount = '';
                    b.pbill_id = null;
                    b.custom = null;
                    this.fetchData();
                });
            }
        }
    }



</script>

<style>

.row div.col-form-label, .row div.col-form-label{

    padding-top: 10px;
}
</style>
